import React from 'react'
import Layout from '../../components/layout'
import EllisHelmet from '../../components/EllisHelmet'
import LocationMenu from '../../components/location-specific/LocationMenu'
import SpecialsPage from '../../components/location-specific/SpecialsPage'

class SpecialsBryant extends React.Component
{
	render()
	{
		const location = "bryant";
		const currentPage = "specials";
		const title = "Specials";

		return (
			<Layout className="menuPage" location={location} currentPage={currentPage}>
				<EllisHelmet page={currentPage} title={title} location={location} />
				<LocationMenu location={location} currentPage={currentPage} />
				<SpecialsPage location={location} />
			</Layout>
		)
	}
}

export default SpecialsBryant